import GoogleMapReact from "google-map-react";
import './Map.css';
import { Icon } from "@iconify/react";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function LocationPin ({ handleClick, location}) {
    const [text, setText] = useState(location.title);

    function handleMouseEnter() {
        setText(location.address);
    }

    function handleMouseLeave() {
        setText(location.title);
    }

    return (
        <div className="pin" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleClick(location)}>
            <Icon icon={location.icon} className="pin-icon" style={{color: location.iconColor}}/>
            <p className="pin-text"> {text} </p>
        </div> 
    )
}

const ListWidget = ({ locations, onClickLocation, defaultLocation }) => {
    return (
        <div className="list-widget">
        <h3>Locations</h3>
        <ul>
            {locations.map((location, index) => (
            <li key={index} onClick={() => onClickLocation(location)}>
                <div className="location-item">
                <div className="location-icon">
                    <Icon icon={location.icon} style={{color: location.iconColor}} />
                </div>
                <div className="location-details">
                    <h4>{location.title}</h4>
                    <p>{location.address}</p>
                </div>
                </div>
            </li>
            ))}
            <li key={locations.length} onClick={() => onClickLocation(defaultLocation)}>
                <div className="location-item">
                <div className="location-icon">
                    <Icon icon={defaultLocation.icon} style={{color: defaultLocation.iconColor}} />
                </div>
                <div className="location-details">
                    <h4>Reset Map</h4>
                    <p>{defaultLocation.address}</p>
                </div>
                </div>
            </li>
        </ul>
        </div>
    );
};

const ListWidgetMobile = ({ locations, onClickLocation, defaultLocation }) => {
    return (
        <div className="list-widget-m">
        <h3></h3>
        <ul>
            {locations.map((location, index) => (
            <li key={index} onClick={() => onClickLocation(location)}>
                <div className="location-item-m">
                <div className="location-icon-m">
                    <Icon icon={location.icon} style={{color: location.iconColor}} />
                </div>
                <div className="location-details-m">
                    <h4>{location.title}</h4>
                    <p>{location.address}</p>
                </div>
                </div>
            </li>
            ))}
            <li key={locations.length} onClick={() => onClickLocation(defaultLocation)}>
                <div className="location-item-m">
                <div className="location-icon-m">
                    <Icon icon={defaultLocation.icon} style={{color: defaultLocation.iconColor}} />
                </div>
                <div className="location-details-m">
                    <h4>Reset Map</h4>
                    <p>{defaultLocation.address}</p>
                </div>
                </div>
            </li>
        </ul>
        </div>
    );
};

function WeddingMap() {
    const defaultLocation =  { address: "Carrollton, TX", lat: 33.02062263269263, lng: -96.93470281395933, icon: "bxs:map", iconColor: "#33131B" };
    const [centerLocation, setCenterLocation] = useState(defaultLocation);
    const [centerZoom, setCenterZoom] = useState(13);
    const [mapDescription, setMapDescription] = useState("Click a location in the list to see details.");
    const [mapLink, setMapLink] = useState("");

    function handleLocationClick (location) {
        setCenterLocation(location);
        if ( location === defaultLocation) {
            setCenterZoom(11)
            setMapDescription("Click a location in the list to see details.");
            setMapLink("");
        }
        else {
            setCenterZoom(17);
            setMapDescription(location.description);
            setMapLink(location.link);
        }
    }

    const locations = [
        { title: "St. Catherine of Siena Catholic Church", address: "1705 E Peters Colony Rd, Carrollton, TX 75007", lat: 33.004938183253195, lng: -96.89755507470572, 
          icon: "map:church", iconColor: "#33131B", description: "All our welcome to join in our Nuptial Mass. Parking is available on site. ", link: "/Details#details-full-content" },
        { title: 'Sparrow On Main', address: '105 E Main St, Lewisville, TX 75057', lat: 33.046531272801396, lng: -96.99387171345926,
          icon: "wpf:wedding-cake", iconColor: "#33131B", description: "Join us for drinks, dinner and dancing. Please see parking map for suggested spots to park adjacent to the venue. ", link: "/Links#parking"},
        { title: 'Best Western', address: '330 E Corporate Dr, Lewisville, TX 75067', lat: 33.01659492254327, lng: -96.98424971397392,
          icon: "ic:twotone-hotel", iconColor: "#33131B", description: "10 rooms are available at a special rate on a first come first serve basis. They can be booked through an online link. ", link: "/Links#accoms"},
        { title: 'Hampton Inn & Suites', address: '2650 Lake Vista Dr, Lewisville, TX 75067', lat:32.99800326275046, lng: -96.96784281098196,
          icon: "ic:twotone-hotel", iconColor: "#33131B", description: "10 rooms are available at a special rate on a first come first serve basis. They can be booked through an online link. ", link: "/Links#accoms"},
        { title: 'Hilton Garden Inn', address: '785 State Hwy 121, Lewisville, TX 75067', lat: 32.99655572083376, lng: -96.96792789266452,
          icon: "ic:twotone-hotel", iconColor: "#33131B", description: "10 rooms are available at a special rate on a first come first serve basis. They can be booked through an online link. ", link: "/Links#accoms" },
    ];

    return  (
        <div className="map">
        <div className="location-description">
            <p>{mapDescription}
            { mapLink && <Link to={mapLink}>More Info</Link>}
            </p>
        </div>
        <div className="google-map">
            <div className="map-frame">
                    <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyDBoKNVKZsW1PFIciegO5EE31GIzBsO98Y" }} 
                                    defaultCenter={defaultLocation} defaultZoom={12}
                                    center={centerLocation} zoom={centerZoom}>
                        {locations.map((loc) => (
                            <LocationPin handleClick={handleLocationClick} location={loc} lat={loc.lat} lng={loc.lng}/>
                        ))}
                    </GoogleMapReact>
            </div>
            <ListWidget locations={locations} onClickLocation={handleLocationClick} defaultLocation={defaultLocation}/>
        </div>
      </div>
    );
}

function WeddingMapMobile() {
    const defaultLocation =  { address: "Carrollton, TX", lat: 33.020071809806836, lng: -96.90905698586593, icon: "bxs:map", iconColor: "#33131B" };
    const [centerLocation, setCenterLocation] = useState(defaultLocation);
    const [centerZoom, setCenterZoom] = useState(11);
    const [mapDescription, setMapDescription] = useState("Click a location in the list to see details.");
    const [mapLink, setMapLink] = useState("");

    function handleLocationClick (location) {
        setCenterLocation(location);
        if ( location === defaultLocation) {
            setCenterZoom(11)
            setMapDescription("Click a location in the list to see details.");
            setMapLink("");
        }
        else {
            setCenterZoom(17);
            setMapDescription(location.description);
            setMapLink(location.link);
        }
    }

    const locations = [
        { title: "St. Catherine of Siena Catholic Church", address: "1705 E Peters Colony Rd, Carrollton, TX 75007", lat: 33.004938183253195, lng: -96.89755507470572, 
          icon: "map:church", iconColor: "#33131B", description: "All our welcome to join in our Nuptial Mass. Parking is available on site.", link: "" },
        { title: 'Sparrow On Main', address: '105 E Main St, Lewisville, TX 75057', lat: 33.046531272801396, lng: -96.99387171345926,
          icon: "wpf:wedding-cake", iconColor: "#33131B", description: "Join us for drinks, dinner and dancing. Please see parking map for suggested spots to park adjacent to the venue. ", link: "/Links#parking"},
        { title: 'Best Western', address: '330 E Corporate Dr, Lewisville, TX 75067', lat: 33.01659492254327, lng: -96.98424971397392,
          icon: "ic:twotone-hotel", iconColor: "#33131B", description: "10 rooms are available at a special rate on a first come first serve basis. They can be booked through an online link. ", link: "/Links#accoms"},
        { title: 'Hampton Inn & Suites', address: '2650 Lake Vista Dr, Lewisville, TX 75067', lat:32.99800326275046, lng: -96.96784281098196,
          icon: "ic:twotone-hotel", iconColor: "#33131B", description: "10 rooms are available at a special rate on a first come first serve basis. They can be booked through an online link. ", link: "/Links#accoms"},
        { title: 'Hilton Garden Inn', address: '785 State Hwy 121, Lewisville, TX 75067', lat: 32.99655572083376, lng: -96.96792789266452,
          icon: "ic:twotone-hotel", iconColor: "#33131B", description: "10 rooms are available at a special rate on a first come first serve basis. They can be booked through an online link. ", link: "/Links#accoms" },
    ];

    return  (
        <div className="locations-container-m">
            <div className="map-m">
                <div className="google-map-m">
                    <ListWidgetMobile locations={locations} onClickLocation={handleLocationClick} defaultLocation={defaultLocation}/>
                    <div className="map-frame-m">
                        <div className="inner-map-size-m" >
                            <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyDBoKNVKZsW1PFIciegO5EE31GIzBsO98Y" }} 
                                            defaultCenter={defaultLocation} defaultZoom={11}
                                            center={centerLocation} zoom={centerZoom} >
                                {locations.map((loc) => (
                                    <LocationPin handleClick={handleLocationClick} location={loc} lat={loc.lat} lng={loc.lng}/>
                                ))}
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>
            </div>
            <div className="location-description-m">
                <p>{mapDescription}
                { mapLink && <Link to={mapLink}>More Info</Link>}
                </p>
            </div>
        </div>
    );
}

export {WeddingMap, WeddingMapMobile};