
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { WeddingMap } from "../Common/map";
import './Details.css';
import { PageTable, SecondaryPageTable } from "../Common/WebLinksTable";
import BackroundTopRuler from '../Pictures/Background/DrawingBackgroundTopRuler.jpeg';
import church from '../Pictures/Theme/Church.svg';
import mule from '../Pictures/Theme/Mule.svg';
import meal from '../Pictures/Theme/Meal.svg';
import dance from '../Pictures/Theme/Dance.svg';
import car from '../Pictures/Theme/Car.png';
import lazo from '../Pictures/Traditions/lazo.jpg';
import arras from '../Pictures/Traditions/arras.jpg';
import biblia from '../Pictures/Traditions/Biblia.jpg'
import mary from '../Pictures/Traditions/Mary.jpg';
import mariachis from '../Pictures/Traditions/Mariachis.png';
import tea from "../Pictures/Traditions/tea.jpg";

async function updateAuthenticate () {
    try {
      const ttoken = localStorage.getItem('TinhToken');
      const requestHeader = {
        Authorization: ttoken
      };

      const response = await fetch ('/api/login/authenticate', {
        method: 'POST',
        headers: requestHeader
      });

      const data = await response.json();

      if(data.success){
        return true;
      }
      else {
        return false;
      }
    }
    catch (err) {
      console.error("Error: ", err);
      return false;
    }
}

function DesktopDetails() {
    const timeline = [
        {
          time: '1:00 PM',
          event: 'We Do',
          description: "All are welcome to join us at the church and witness our commitment to one another, as we exchange vows and celebrate our union with a Catholic Nuptial Mass. However, the part of the mass known as Holy Communion is reserved for those who are Catholic. During Holy Communion, those who are not Catholic will instead be invited to receive a blessing.",
          icon: church,
        },
        {
          time: '4:00 PM',
          event: 'We Toast',
          description: "Enjoy an open bar and a selection of delectable hors d'oeuvres as you mingle with the happy couple and their loved ones. This is a time to relax, socialize, and toast to the newlyweds before dinner is served.",
          icon: mule,
        },
        {
          time: '5:00 PM',
          event: 'We Eat',
          description: "As the reception is formally kicked off accompanied by the lively sounds of a mariachi band, we invite you to join us for a dinner service including both chicken and beef.",
          icon: meal,
        },
        {
          time: '6:00 PM',
          event: 'We Party',
          description: 'The dance floor is now open! A DJ will be playing a great mix of songs all night long to keep you moving. Be sure to refuel with a some catered snacks and refreshing beverages from the open bar in between songs.',
          icon: dance,
        },
        {
          time: '11:00 PM',
          event: 'Farewell',
          description: "As the clock strikes 11, we must reluctantly bid you all farewell and bring our joyous celebration to a close. Before you depart, please join us one last time to send the bride and groom off in style.",
          icon: car,
        },
      ];

      const traditions = [
        {
            title: "Sien Doan Taa",
            description:"Sien Doan Taa is a deeply rooted Cambodian Buddhist tradition that involves honoring and paying respects to one's ancestors. This ancestor veneration tradition is believed to have spiritual and cultural significance, connecting the present generation to their ancestral lineage and heritage. It is a cherished practice that reflects the importance of family and honoring one's roots. ",
            image: tea
        },
        {
            title: "El Lazo",
            description: "The inclusion of the lazo ritual in a Catholic nuptial mass is a beautiful way to symbolize the couple's infinite love and faith, as they become 'one flesh' in the sacrament of marriage. The lazo is a large rosary, rope, or garland that is placed around the bride and groom's shoulders during the nuptial blessing. The lazo is usually provided by a happily married couple, known as the Padrinos de Lazo. The lazo is draped over the couple's shoulders, forming an infinity symbol to represent the couple's eternal and unending love and commitment to one another. During the nuptial blessing, the priest or officiant will often say a prayer over the couple as the lazo is placed around them. The prayer typically asks for God's blessing on the couple's union and for their marriage to be filled with health, faithfulness, children, strength, and love. ",
            image: lazo
        },
        {
            title: "Las Arras",
            description: "Las arras are a cherished tradition in Catholic Hispanic wedding ceremonies. They consist of 13 gold or silver coins that are blessed by the priest and presented by the groom to the bride during the ceremony. The 13 coins carry multiple meanings; 12 of the coins represent Jesus’ 12 apostles and the importance of putting God first in their marriage. The 12 coins also represent the 12 months of the year and prosperity for the newlyweds as they combine finances. The last coin symbolizes overabundance and reminds the couple that they should always try to give back to the less fortunate if they're blessed with more than they need. The Padrinos de Las Arras are the ones to purchase the 13 coins for the couple. This signifies that the Padrinos have shared their wealth of knowledge with the bride and groom prior to the wedding concerning life and marriage. oNce the coins are blessed, the couple will pass them back and forth as a symbol of their shared commitment to providing for their future ",
            image: arras
        },
        {
            title: "El Biblio y Rosario",
            description: "During a traditional Catholic Hispanic wedding ceremony, the couple will be presented with a Bible and rosary by their Padrinos de Biblia y Rosario. The Bible and rosary are blessed by the priest during the nuptial mass and then presented to the bride and groom. This symbolic gesture represents the importance of faith and prayer in the couple's marriage. The Bible signifies the Word of God that will guide their relationship, while the rosary is a tool for devotion and invoking the intercession of the Blessed Virgin Mary. ",
            image: biblia
        },
        {
            title: "Presentation of the Bouquet",
            description: "One traditional Catholic custom during a nuptial mass is for the bride to present a bouquet of flowers to a statue or image of the Virgin Mary. The bride will place a bouquet at the feet of the statue as a gesture entrusting her marriage to the care and intercession of the Blessed Mother. The presentation of the bridal bouquet to Mary is a beautiful way for the couple to recognize the important role of the Blessed Mother in the Catholic faith and to seek her guidance as they begin their life together.",
            image: mary
        },
        {
            title: "Las Mariachis",
            description: "Mariachi bands are a quintessential part of traditional Mexican wedding celebrations. As the newlyweds make their grand entrance into the reception, a lively mariachi band will lead the way, serenading the couple with vibrant, celebratory music. Their brassy trumpets, guitars, and violins will fill the air, setting the tone for a night of joyous celebration.",
            image: mariachis
        }
      ];


    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        async function checkAuthentication() {
            const isAuthenticated = await updateAuthenticate();

            if(!isAuthenticated){
                navigate('/');
            }
        }

        checkAuthentication();

        if(location.hash) {
          const element = document.querySelector(location.hash);
    
          if (element)
            element.scrollIntoView({ behavior: 'smooth' });
        }
    
    }, [navigate, location])


    return (
        <div className="DesktopDetails" >
            <div className="grid-background">
                <div id="details-full-content">
                    <div className="pages">
                        <img className="ruler-top" src={BackroundTopRuler} />
                        <div className="details-frame">
                            <div className="wedding-timeline">
                                <h2>Wedding Timeline</h2>
                                <ul style={{paddingInlineStart: 0}}>
                                    {timeline.map((item, index) => (
                                        (index %2 === 0) ? 
                                        (
                                            <li key={index} className="timeline-item">
                                                <img src={item.icon} style={{transform: 'rotate(5deg)'}}/>
                                                <span className="time">{item.time}</span>
                                                <div className="event">{item.event}</div>
                                            </li> 
                                        ) :
                                        (
                                            <li key={index} className="timeline-item">
                                                <img src={item.icon} style={{transform: 'rotate(-5deg)'}}/>
                                                <span className="time">{item.time}</span>
                                                <div className="event">{item.event}</div>
                                            </li> 
                                        )
                                    ))}
                                </ul>
                            </div>
                            <div className="event-details">
                                <h2>Event Details</h2>
                                <ul>
                                    {timeline.map((item, index) => (
                                        <div className="event-description">
                                            <Icon icon="ci:heart-02" style={{color: '#33131B', marginRight: '1rem'}} />
                                            <span style={{fontSize: '1.5rem'}}>{item.event}</span> - {item.description}
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <PageTable page="details" />
                        <img className="ruler-bottom" src={BackroundTopRuler} />
                    </div>
                    <div className="pages">
                        <img className="ruler-top" src={BackroundTopRuler} />
                        <div className="details-frame">
                            <div id="map" className="map-content">
                                <h2 className="map-title" >Tinh Wedding Locations</h2>
                                <div className='wedding-map' >
                                    <WeddingMap />
                                </div>
                            </div>
                        </div>
                        <SecondaryPageTable pageNumber="2" />
                        <img className="ruler-bottom" src={BackroundTopRuler} />
                    </div>
                    <div className="pages">
                        <img className="ruler-top" src={BackroundTopRuler} />
                        <div id="traditions" className="traditions-frame">
                            <p className="traditions-header">Wedding Traditions:</p>
                            <div className="traditions" >
                                {traditions.map((item, index) => (
                                    <div className="note-box">
                                        <div className="note-number"><p>{index + 1}</p></div>
                                        <p> {item.description}</p>
                                        <img src={item.image} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <SecondaryPageTable pageNumber="3" />
                        <img className="ruler-bottom" src={BackroundTopRuler} />
                    </div>
                    <div className="pages">
                        <img className="ruler-top" src={BackroundTopRuler} />
                            <div id="faqs" className="faqs-details">
                                <h2>FAQs</h2>
                                <li>When should I RSVP?</li>
                                <p>     Please RSVP by September 13, 2024 to reserve a seat and a meal at our reception. </p>
                                <li>What time should I arrive at the ceremony/reception?</li>
                                <p>     Please arrive at least 15 mins before the ceremony as Mass will begin promptly at 1:00PM. Cocktail hour will begin at 4:00PM and dinner will be served at 5:00PM.</p>
                                <li>Will events be indoors or outdoors?</li>
                                <p>     While cocktail hour will be held in the venue's Garden Patio, both ceremony and dinner/dance will be held indoors.</p>
                                <li>Is there a dress code?</li>
                                <p>     Cocktail attire: cocktail dresses, dressy jumpsuits, dark jackets and slacks.</p>
                                <li>Can I bring a date?</li>
                                <p>     While we would love to share our day with so many more people, our venue has a capacitiy limit. As such, our guest list is limited to those addressed in invitations. Thank you for understanding!.</p>
                                <li>Are children welcome?</li>
                                <p>     Children have been included in the number of reserved seats for your family. Please contact Sam or Stephen if you have any questions.</p>
                            </div>
                        <SecondaryPageTable pageNumber="4" />
                        <img className="ruler-bottom" src={BackroundTopRuler} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesktopDetails;