import { useEffect } from 'react';
import './App.css';
import DesktopComponent from './DesktopComponent/App';
import MobileComponent from './MobileComponent/App';

function App() {  

  const currWidth = window.innerWidth;
  
  useEffect(() => {
    const updateViewCount = async () => {
      try {
        const response = await fetch('/api/stats/incrementViewCount', {
          method: 'POST'
        });
      } catch (error) {
        console.error(error);
      }
    }

    updateViewCount();
  }, [])

  return (  
    <div className="App">
      {currWidth <= 1200 ? <MobileComponent /> : <DesktopComponent /> }
    </div>
  );
}           

export default App;
