import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './WebLinksTable.css';
import TinhLogo from '../Pictures/TinhsLogo.png';
import TinhNameLogo from '../Pictures/TinhsNameLogo.png';
import showpwd from '../Pictures/showpwd.png';
import noshowpwd from '../Pictures/noshowpwd.png';

function switchLinks (link){

    switch(link){
        case 'wedding info':
            return '/Details';
        case 'our story':
            return '/#our-story';
        case 'traditions':
            return "/Details#traditions";
        case 'map':
            return "/Details#map";
        case 'faqs':
            return "/Details#faqs";
        case 'accommodations':
            return "/Links#accoms";
        case 'rsvp':
            return "/Links";
        case 'registry':
            return "/Links#registry";
        default:
            return '/';
    }
}

async function authenticateRoute (navigate, page, link, modalOpen) {
    try {
        const ttoken = localStorage.getItem('TinhToken');

        const requestHeader = {
        Authorization: ttoken
        };

        const response = await fetch ('/api/login/authenticate', {
        method: 'POST',
        headers: requestHeader
        });

        const data = await response.json();

        if(data.success){
            navigate(switchLinks(link));
        }else if (page === "home") {
            modalOpen(true);
        }else {
            navigate("/");
        }
    }
    catch (err) {
      console.error("Error: ", err);
    }
}

function updatePwdIcon (pwdSrc, setPwdSrc) {

    if(pwdSrc == showpwd) {
      setPwdSrc(noshowpwd);
    }
    else {
      setPwdSrc(showpwd);
    }
}

function updatePasswordText (e, setPwdString) {
    setPwdString(e.target.value);
}

async function validatePwd (setPwdErrorTxt, setLoginModalOpen, pwdString) {
    const requestBody = {
        code: pwdString
    };

    try {
        const response = await fetch('/api/login', {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(requestBody)
        });

        const data = await response.json();

        if(data.success) { 
        localStorage.setItem('TinhToken', data.token);
        setPwdErrorTxt("");
        setLoginModalOpen(false);
        }
        else {
        setPwdErrorTxt("Invalid password");
        }

    } catch (error) {
        console.error(error);
    }
}

function PageTable ({page}) {
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const [pwdSrc, setPwdSrc] = useState(showpwd);
    const [pwdString, setPwdString] = useState("");
    const [pwdErrorTxt, setPwdErrorTxt] = useState("");
    const [lastLink, setLastLink] = useState("/");

    const navigate = useNavigate();

    function onRouteClick(link) {
        setLastLink(link);
        authenticateRoute(navigate, page, link, setLoginModalOpen);
    }

    return(
        <div className="drawing-info-links"> 
            <Modal id="login-modal" style={{overlay:{backgroundColor: "rgba(0,0,0,0.5)"},content:{padding: '1%', background: "#F1EFF0"}}} isOpen={loginModalOpen} ariaHideApp={false} onAfterClose={() => authenticateRoute(navigate, page, lastLink, null)}>
                <div className="login-modal-body">
                    <button className="login-modal-button" onClick={() => setLoginModalOpen(false)}>X</button>
                    <div id="login-modal-body-content">
                        <img className='login-modal-logo' src={TinhLogo} />
                        <div className="login-modal-pwd">
                            <input className='login-modal-input' type={ (pwdSrc == showpwd) ? "password" : "text"} defaultValue="password" onChange={(e) => updatePasswordText(e, setPwdString)} />
                            <img onClick={() => updatePwdIcon(pwdSrc, setPwdSrc)} src={pwdSrc}/>
                        </div>
                        <button className="login-modal-submit" onClick={() => validatePwd(setPwdErrorTxt, setLoginModalOpen, pwdString)} >Submit</button>
                        <p>{pwdErrorTxt}</p>
                    </div>
                </div>
            </Modal>
            <div className="links-table"> 
                <div className="lt-cola">
                <div className="lt-cola-1">
                    <img className="tinh-logo" src={TinhLogo} />  
                </div>
                <div className="lt-cola-2">
                    <h>Date:  </h>
                    <p>October 26, 2024</p>
                </div>
                </div>
                <div className="lt-colb">
                <div className="lt-colb-row">
                    <h>Title:  </h>
                    <p className="page-link"><a onClick={() => onRouteClick('home')} ><u>Tinh Wedding 2024</u></a></p>
                </div>
                <div className="lt-colb-row">                
                    <h>Project:  </h>
                    { (page === "home") && <p className="page-link"><a onClick={() => onRouteClick('wedding info')}><u>Wedding Info</u></a></p>}
                    { (page === "details") && <p className="page-link"><a onClick={() => onRouteClick('our story')}><u>Our Story</u></a></p>}
                    { (page === "links") && <p className="page-link"><a onClick={() => onRouteClick('wedding info')}><u>Wedding Info</u></a></p>}
                </div>
                <div className="lt-colb-row">
                    <div className="lt-colb-row2">                  
                        <h>Design:  </h>
                        { (page === "home") && <p className="page-link"><a onClick={() => onRouteClick('our story')} ><u>Our Story</u></a></p>}
                        { (page === "details") && <p className="page-link"><a onClick={() => onRouteClick('traditions')} ><u>Traditions</u></a></p>}
                        { (page === "links") && <p className="page-link"><a onClick={() => onRouteClick('our story')}><u>Our Story</u></a></p>} 
                    </div>
                    <div className="lt-colb-row2">                
                    <h>Approval:  </h>
                    <p className="page-link"><a onClick={() => onRouteClick('rsvp')} ><u>RSVP</u></a></p>
                    </div>
                </div>
                <div className="lt-colb-row">               
                <h>Details:  </h>
                    { (page === "home") && <p className="page-link"><a onClick={() => onRouteClick('accommodations')} ><u>Accommodations</u></a></p>}
                    { (page === "details") && <p className="page-link"><a onClick={() => onRouteClick('map')} ><u>Wedding Map</u></a></p>}
                    { (page === "links") && <p className="page-link"><a onClick={() => onRouteClick('accommodations')} ><u>Accommodations</u></a></p>}
                </div>
                <div className="lt-colb-row">
                    <div className="lt-colb-row3">
                        <p className="page-link"><a onClick={() => onRouteClick('registry')} ><u>Registry</u></a></p>
                    </div>
                    <div className="lt-colb-row3">
                        <p className="page-link"><a onClick={() => onRouteClick('faqs')} ><u>FAQs</u></a></p>
                    </div>
                    <div className="lt-colb-row3">                     
                    <p><a href="mailto:contact@FloTinhDownTheAisle.com" >Contact</a></p>
                    </div>
                </div>
                </div>
            </div>
        </div>     
    );
}

function PageTableMobile({page}) {
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const [pwdSrc, setPwdSrc] = useState(showpwd);
    const [pwdString, setPwdString] = useState("");
    const [pwdErrorTxt, setPwdErrorTxt] = useState("");
    const [lastLink, setLastLink] = useState("/");

    const navigate = useNavigate();

    function onRouteClick(link) {
        setLastLink(link);
        authenticateRoute(navigate, page, link, setLoginModalOpen);
    }

    return (
        <div className="tp-corner-table-m">
            <Modal id="login-modal" style={{overlay:{backgroundColor: "rgba(0,0,0,0.5)", zIndex: 3},content:{padding: '1%', background: "#F1EFF0", height: '60vw', width: '90vw', inset: '2%', marginLeft: 0, marginTop: '35%'}}} isOpen={loginModalOpen} ariaHideApp={false} onAfterClose={() => authenticateRoute(navigate, page, lastLink, null)}>
            <div className="login-modal-body">
                <button className="login-modal-button" onClick={() => setLoginModalOpen(false)}>X</button>
                <div id="login-modal-body-content">
                    <img className='login-modal-logo' style={{height: '45%'}} src={TinhLogo} />
                    <div className="login-modal-pwd" style={{width: '80%'}}>
                        <input className='login-modal-input' style={{fontSize: '2.5vh'}} type={ (pwdSrc == showpwd) ? "password" : "text"} defaultValue="password" onChange={(e) => updatePasswordText(e, setPwdString)} />
                        <img onClick={() => updatePwdIcon(pwdSrc, setPwdSrc)} src={pwdSrc}/>
                    </div>
                    <button style={{fontSize: '2.5vw'}} className="login-modal-submit" onClick={() => validatePwd(setPwdErrorTxt, setLoginModalOpen, pwdString)} >Submit</button>
                    <p style={{fontSize: '2vh'}}>{pwdErrorTxt}</p>
                </div>
            </div>
            </Modal>
            <div className="links-table-m"> 
                <div className="lt-cola-m">
                <div className="lt-cola-1-m">
                    <img className="tinh-logo-m" src={TinhLogo} />  
                </div>
                <div className="lt-cola-2-m">
                    <h>Date:  </h>
                    <p>October 26, 2024</p>
                </div>
                </div>
                <div className="lt-colb-m">
                <div className="lt-colb-row-m">
                    <h>Title:  </h>
                    <p className="page-link"><a onClick={() => onRouteClick('home')} ><u>Tinh Wedding 2024</u></a></p>
                </div>
                <div className="lt-colb-row-m">                
                    <h>Project:  </h>
                    { (page === "home") && <p className="page-link"><a onClick={() => onRouteClick('wedding info')}><u>Wedding Info</u></a></p>}
                    { (page === "details") && <p className="page-link"><a onClick={() => onRouteClick('our story')}><u>Our Story</u></a></p>}
                    { (page === "links") && <p className="page-link"><a onClick={() => onRouteClick('wedding info')}><u>Wedding Info</u></a></p>}                </div>
                <div className="lt-colb-row-m">
                    <div className="lt-colb-row2-m">                
                    <h>Design:  </h>
                    { (page === "home") && <p className="page-link"><a onClick={() => onRouteClick('our story')} ><u>Our Story</u></a></p>}
                    { (page === "details") && <p className="page-link"><a onClick={() => onRouteClick('traditions')} ><u>Traditions</u></a></p>}
                    { (page === "links") && <p className="page-link"><a onClick={() => onRouteClick('our story')}><u>Our Story</u></a></p>}
                    </div>
                    <div className="lt-colb-row2-m">                
                    <h>Approval:  </h>
                    <p className="page-link"><a onClick={() => onRouteClick('rsvp')} ><u>RSVP</u></a></p>
                    </div>
                </div>
                <div className="lt-colb-row-m">               
                    <h>Details:  </h>
                    { (page === "home") && <p className="page-link"><a onClick={() => onRouteClick('accommodations')} ><u>Accommodations</u></a></p>}
                    { (page === "details") && <p className="page-link"><a onClick={() => onRouteClick('map')} ><u>Wedding Map</u></a></p>}
                    { (page === "links") && <p className="page-link"><a onClick={() => onRouteClick('accommodations')} ><u>Accommodations</u></a></p>}
                </div>
                <div className="lt-colb-row-m">
                    <div className="lt-colb-row3-m">
                        <p className="page-link"><a onClick={() => onRouteClick('registry')} ><u>Registry</u></a></p>
                    </div>
                    <div className="lt-colb-row3-m">
                        <p className="page-link"><a onClick={() => onRouteClick('faqs')} ><u>FAQs</u></a></p>
                    </div>
                    <div className="lt-colb-row3-m">
                    <p><a href="mailto:contact@FloTinhDownTheAisle.com">Contact</a></p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

function SecondaryPageTable({pageNumber}) {

    function onRouteClick() {
        window.scrollTo(0,0);
    }

    return (
      <div className="sec-table-frame">
                <div className="sec-table"> 
                  <div className="st-cola">
                  <img className="tinh-name" src={TinhNameLogo} />                
                  </div>
                  <div className="st-col-link">
                    <p className="page-link"><a onClick={() => onRouteClick()}><u>Home</u></a></p>
                  </div>
                  <div className="st-col-link">
                    <p className="page-link"><a href="mailto:contact@FloTinhDownTheAisle.com" >Contact</a></p>
                  </div>
                  <div className="st-col-link">
                    <p>Pg: {pageNumber}</p>
                  </div>
                </div>
              </div>
    );
  }

  function SecondaryPageTableMobile({pageNumber}) {

    function onRouteClick() {
        window.scrollTo(0,0);
    }

    return (
      <div className="sec-table-frame-m">
                <div className="sec-table-m"> 
                  <div className="st-cola-m">
                  <img className="tinh-name-m" src={TinhNameLogo} />                
                  </div>
                  <div className="st-col-link-m">
                    <p className="page-link"><a onClick={() => onRouteClick()}><u>Home</u></a></p>
                  </div>
                  <div className="st-col-link-m">
                    <p className="page-link"><a href="mailto:contact@FloTinhDownTheAisle.com" >Contact</a></p>
                  </div>
                  <div className="st-col-link-m">
                    <p>Pg: {pageNumber}</p>
                  </div>
                </div>
              </div>
    );
  }

  

  export {PageTable, PageTableMobile, SecondaryPageTable, SecondaryPageTableMobile};