import "./Links.css"
import BackroundTopRuler from '../Pictures/Background/DrawingBackgroundTopRuler.jpeg';
import { PageTableMobile, SecondaryPageTableMobile } from "../Common/WebLinksTable";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import parkingMap from "../Pictures/parkingMap.png"

function MobileLinks () {

    async function updateAuthenticate () {
        try {
          const ttoken = localStorage.getItem('TinhToken');
          const requestHeader = {
            Authorization: ttoken
          };
    
          const response = await fetch ('/api/login/authenticate', {
            method: 'POST',
            headers: requestHeader
          });
    
          const data = await response.json();
          if(data.success){
            return true;
          }
          else {
            return false;
          }
        }
        catch (err) {
          console.error("Error: ", err);
          return false;
        }
    }

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        async function checkAuthentication() {
            const isAuthenticated = await updateAuthenticate();

            console.log(isAuthenticated);

            if(!isAuthenticated){
                navigate('/');
            }
        }

        checkAuthentication();

        if(location.hash) {
          const element = document.querySelector(location.hash);
    
          if (element)
            element.scrollIntoView({ behavior: 'smooth' });
        }
    
    }, [navigate, location])

    return(
        <div className="MobileLinks">
            <div className="grid-background">
                <div id="links-full-content">
                    <div className="pages">
                        <img className="ruler-top" src={BackroundTopRuler} />
                        <div className="rsvp-content-m">
                            <div className="rsvp-details-m">
                                <div className="links-title-m">RSVP Details</div>
                                <p>We are using Zola to handle our online RSVPS. Clicking the link below, will open a new tab to allow you to RSVP. Please let us know if you have any questions or would prefer to RSVP directly to Sam or Stephen.</p>
                                <a href="https://www.zola.com/wedding/flotinhdowntheaisle/rsvp" target="_blank" rel="noreferrer">RSVP Here</a>
                                <p style={{marginBlockEnd: 0, marginBlockStart: 0}}>Notes:</p>
                                <li>Please RSVP by September 13, 2024</li>
                                <li>While we would love to share our day with so many more people, our venue has a capacitiy limit. As such, our guest list is limited to those addressed on invitations. </li>
                            </div>
                        </div>                        
                        <PageTableMobile page="links" />
                        <img className="ruler-bottom" src={BackroundTopRuler} />
                    </div>
                    <div className="pages">
                        <img className="ruler-top" src={BackroundTopRuler} />
                        <div className="rsvp-content-m">
                            <div id="accoms" className="accommodations-details-m">
                                <div className="links-title-m">Accommodations</div>
                                <p>While our ceremony will take place in Carrollton, our reception will be held in Lewisville.</p>
                                <p>We have reserved 10 rooms at each of the following hotels in Lewisville, to be booked by our guests on a first serve first come basis. Bookings are for Oct. 25th - Oct. 27th.</p>
                                <p>Each hotel has a link that is tied to a special rate for our wedding. Rates will be available until Sept 25, 2024.</p>
                                <div className="hotel-links-m">
                                    <span>Best Western: <a href="https://www.bestwestern.com/en_US/book/hotel-rooms.44464.html?groupId=Q92SR2F6" target="_blank" rel="noreferrer">Book Here</a></span>
                                    <span>Hampton Inn & Suites: <a href="https://group.hamptoninn.com/itep0m" target="_blank" rel="noreferrer">Book Here</a></span>
                                    <span>Hilton Garden Inn: <a href="https://links.h6.hilton.com/f/a/qX_bnWZvElhNvTWHXrqVcQ~~/AAQRxQA~/RgRnxNu7P0RTaHR0cHM6Ly93d3cubXktZXZlbnQuaGlsdG9uLmNvbS9kYWxsZWdpLWZ0d2VkLTIwMWEzNjM1LTg2N2EtNGVmMy1hNmQ0LTg0MTNmN2Y4MGFiYy9XA3NwY0IKZdW7VuJlKSJYeFIbcmFjaGVsLmJhbHRpZXJyYUBoaWx0b24uY29tWAQAAALi" target="_blank" rel="noreferrer">Book Here</a></span>
                                </div>
                                <p style={{textAlign: "center"}} >All hotels are within 10 mins from the venue.</p>
                                <p style={{textAlign: "center"}} ><Link to="/Details#map">See locations on map</Link> </p>
                            </div>
                        </div>                        
                        <SecondaryPageTableMobile pageNumber="2" /> 
                        <img className="ruler-bottom" src={BackroundTopRuler} />
                    </div>
                    <div className="pages">
                        <img className="ruler-top" src={BackroundTopRuler} />
                        <div className="rsvp-content-m">
                            <div id="parking" className="parking-details-m">
                                <div className="links-title-m">Venue Parking</div>
                                <p>Parking at the venue is reserved for vendors and immediate family. Parking for guests is available along the streets surrounding the venue or at City Hall.</p>
                                <p>Additionally public parking is currently available at parking garages across from the venue. Updated info on these will be provided if anything changes.</p>
                                <img src={parkingMap} />
                                <p style={{textAlign: "center"}} ><Link to="/Details#map">Back to map</Link> </p>
                            </div>
                        </div>                        
                        <SecondaryPageTableMobile pageNumber="3" /> 
                        <img className="ruler-bottom" src={BackroundTopRuler} />
                    </div>
                    <div className="pages">
                        <img className="ruler-top" src={BackroundTopRuler} />
                        <div id="registry" className="registry-content-m">
                            <div className="registry-details-m">
                                <div className="links-title-m">Registry</div>
                                <p>Family & friends, we are so excited to celebrate our marriage with all of you! Please know that your presence on our special day will be the greatest gift we could receive. As such, enjoying the day with you would be more than enough.</p>
                                <p>However if you feel inclined to give a gift, we have set up a couple small registries with some items that would help us, as we continue building our life together. We've also created a house/honeymoon fund to help us save for our future adventures. </p>
                                <div className="registry-links-m">
                                    <span>Amazon: <a href="https://www.amazon.com/wedding/share/FloTinh-Wedding" target="_blank" rel="noreferrer">See Registry</a></span>
                                    <span>Target: <a href="https://www.target.com/gift-registry/gift/flotinh-wedding" target="_blank" rel="noreferrer">See Registry</a></span>
                                    <span>Venmo Fund: <a href="https://venmo.com/u/The-Tinhs-2024" target="_blank" rel="noreferrer">Open Venmo</a></span>
                                </div>
                            </div>
                        </div>                        
                        <SecondaryPageTableMobile pageNumber="3" /> 
                        <img className="ruler-bottom" src={BackroundTopRuler} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileLinks;