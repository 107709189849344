import { useState, useRef, createElement, createContext, useEffect} from 'react';
import './VerticalTimeline.css';
import RightArrow from '../Pictures/Theme/RightArrow.png';
import LeftArrow from '../Pictures/Theme/LeftArrow.png';
import Modal from 'react-modal';

function PhotoReel ({reelPhotos, setReelId, reelId, setPic, setCurrentImgFile, openModal}) {
    const reelLength = reelPhotos.length;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImgUrl, setCurrentImgUrl] = useState("");

    function HandleSwipe (e) {

        if(e.target.id == 'left'){

            if(currentIndex > 0){   
                setCurrentIndex(currentIndex - 1);
            }
            else if(currentIndex == 0){
                setCurrentIndex(reelLength - 1);
            }
        }
        else if(e.target.id == 'right'){
        
            if(currentIndex < reelLength - 1){    
                setCurrentIndex(currentIndex + 1);
            }
            else if(currentIndex == reelLength - 1){
                setCurrentIndex(0);
            }
        }     

        loadPhoto();
    }

    const loadPhoto = async () => {
        try {
            const ttoken = localStorage.getItem('TinhToken');
            const requestHeader = {
                Authorization: ttoken
            };

            const response = await fetch ("/api/" + reelPhotos[currentIndex].src, {
                method: 'GET',
                headers: requestHeader
            });
        
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);
            setCurrentImgUrl(objectURL); 
        }
        catch (err) {
            console.error("Timeline Photo Error: ", err);
        }

    }

    useEffect(() => {

        if(currentImgUrl == "")
            loadPhoto();

    }, [currentImgUrl])


    return (
      <>
      <div className="reel-arrow-frame">
        <img id="left" onClick={(e) => {HandleSwipe(e); setReelId(reelId)}} src={LeftArrow} />
        <div className="photo-frame" >
            <img key={reelPhotos[currentIndex].id} src={currentImgUrl} alt={reelPhotos[currentIndex].alt} onClick={(e) => {setReelId(reelId); setPic(currentIndex); setCurrentImgFile(currentImgUrl); openModal(true);}}/>
        </div>
        <img id="right" onClick={(e) => {HandleSwipe(e); setReelId(reelId)}} src={RightArrow} />
      </div>  
      </>
    );
}

function ImagesComponent({photos, cardHeight, changeModal}) {
    const [reelModalOpen, setReelModalOpen] = useState(false);
    const [selectedReel, setSelectedReel]   = useState(0);
    const [activePicture, setActivePicture] = useState(0);
    const [currentImgUrl, setCurrentImgUrl] = useState("");
    const [connectorWidth, setConnectorWidth] = useState(40);
    const [evenMarginTop, setEvenMarginTop] = useState(0);
    const [captionSize, setCaptionSize] = useState("1.75vh");
    const [bgLine, setBgLine] = useState("linear-gradient(black,black) no-repeat 50% 100%/2px 1vh, linear-gradient(black,black) no-repeat 50% 0%/2px 5.5vh");
    const [reelModalHeight, setReelModalHeight] = useState("85vh");

    function LoadImage() {

        const reelModalFrame = document.querySelector("#modal-image-frame");
        const firstImage = document.createElement('img');
        const defaultImage = document.querySelector("#modal-image");

        firstImage.setAttribute("id", "active-modal-image");
        firstImage.src = currentImgUrl;
        reelModalFrame.replaceChild(firstImage, defaultImage);

    }

    function NextImage(e){
        const reelModalFrame = document.querySelector("#modal-image-frame");
        const nextImage = document.createElement('img');
        const currentImage = document.querySelector("#active-modal-image");

        if(e.target.id == 'left'){

            if( activePicture > 0){   
            
                setActivePicture(activePicture - 1);
            }
            else if(activePicture == 0){
            
                setActivePicture(photos[selectedReel].photos.length - 1)
            }
        }
        else if(e.target.id == 'right'){
        
            if(activePicture < photos[selectedReel].photos.length - 1){    
            
                setActivePicture(activePicture + 1);
            }
            else if(activePicture == photos[selectedReel].photos.length - 1){
            
                setActivePicture(0);
            }
        }
        
        loadPhoto(photos[selectedReel].photos[activePicture].src);

        nextImage.setAttribute("id", "active-modal-image");
        nextImage.src = currentImgUrl;
        reelModalFrame.replaceChild(nextImage, currentImage);

    }

    const loadPhoto = async (imgSrc) => {
        try {
            const ttoken = localStorage.getItem('TinhToken');
            const requestHeader = {
                Authorization: ttoken
            };

            const response = await fetch ("/api/" + imgSrc, {
                method: 'GET',
                headers: requestHeader
            });
        
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);
            setCurrentImgUrl(objectURL); 
        }
        catch (err) {
            console.error("Timeline Photo Error: ", err);
        }

    }

    useEffect(() => {
        const currWidth = window.innerWidth;
    
        if(currWidth > 1200) {
            setConnectorWidth(47.5);
            setEvenMarginTop(0);
            setCaptionSize("1.5vw");
            setBgLine("none");
            setReelModalHeight("85vh");

        }
        else
        {
            setConnectorWidth(95);
            setEvenMarginTop(10);
            setCaptionSize("1rem")
            setBgLine("linear-gradient(black,black) no-repeat 50% 100%/2px 10px, linear-gradient(black,black) no-repeat 50% 0%/2px 40px");
            setReelModalHeight("50vh");
        }

    }, [currentImgUrl, connectorWidth, evenMarginTop, captionSize, bgLine, reelModalHeight])

    return (
    <div className="imgs-comp">
        <Modal id="reel-modal" style={{overlay:{backgroundColor: "rgba(0,0,0,0.5)"},content:{background: "#F1EFF0", height: reelModalHeight}}} isOpen={reelModalOpen} onAfterOpen={LoadImage} ariaHideApp={false}>
            <div className="reel-modal-body">
                <button className="modal-button" onClick={() => setReelModalOpen(false)}>X</button>
                <div id="modal-body-content">
                    <img id="left" className="modal-arrow" onClick={NextImage} src={LeftArrow} />
                    <div id="modal-image-frame" >
                        <img id="modal-image" />
                    </div>
                    <img id="right" className="modal-arrow" onClick={NextImage} src={RightArrow} />
                </div>
                <div className="modal-caption">
                    <p>{photos[selectedReel].caption}</p>
                </div>
            </div>
        </Modal>
        {photos.map((reel,reelId) => (
            ((reelId % 2 === 0)) ? (
                <div className="timeline-connector" style={{height: cardHeight + 'px', width: connectorWidth + '%', paddingTop: evenMarginTop + '%', background: bgLine}}>
                    <div className="timeline-card" style={{transform: 'rotate(-5deg)'}}>
                        <PhotoReel reelPhotos={reel.photos} setReelId={setSelectedReel} reelId={reelId} setPic={setActivePicture} setCurrentImgFile={setCurrentImgUrl} openModal={setReelModalOpen} />
                        <p style={{fontSize: captionSize}}>{reel.caption}</p>
                    </div>
                    <div className="timeline-circle-even">
                    <img key={reel.id} src={reel.icon} alt={reel.alt} />
                    </div>
                </div>
            ) :
            (
                <div className="timeline-connector" style={{height: cardHeight + 'px', width: connectorWidth + '%', paddingTop: 10 + '%', background: bgLine}}>
                    <div className="timeline-circle-odd">
                    <img key={reel.id} src={reel.icon} alt={reel.alt} />
                    </div>
                    <div className="timeline-card" style={{transform: 'rotate(5deg)'}}>
                        <PhotoReel reelPhotos={reel.photos} setReelId={setSelectedReel} reelId={reelId} setPic={setActivePicture} setCurrentImgFile={setCurrentImgUrl} openModal={setReelModalOpen} />
                        <p style={{fontSize: captionSize, marginBlockStart: 0}}>{reel.caption}</p>
                    </div>
                </div>
            )
            ))}
    </div>
    );
  }

function VerticalTimeline({photos}){
    const [singleHeight, setSingleHeight] = useState(window.innerHeight * 0.6);
    const [bgLine, setBgLine] = useState("linear-gradient(black,black) no-repeat center/2px 95%");
    const [fullWidth, setFullWidth] = useState("100rem");

    useEffect(() => {
        const currWidth = window.innerWidth;
    
        if(currWidth > 1200) { 
            setSingleHeight(750);
            setBgLine("linear-gradient(black,black) no-repeat center/2px 95%");
            setFullWidth("95%");
        }
        else {
            setSingleHeight(450);
            setBgLine("none");
            setFullWidth("95%");
        }

    }, [singleHeight,bgLine])

    return (
        <div className="vertical-timeline" style={{background: bgLine, width: fullWidth}} >
            <ImagesComponent photos={photos} cardHeight={singleHeight}/>
        </div>
    );
}

export default VerticalTimeline;