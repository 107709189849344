import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Home.css';
import { PageTableMobile, SecondaryPageTableMobile } from '../Common/WebLinksTable';
import VerticalTimeline from '../Common/VerticalTimeline';
import BackroundTopRuler from '../Pictures/Background/DrawingBackgroundTopRuler.jpeg';
import DraftingPencil from '../Pictures/Theme/Drafting Pencil.png';
import CakeSketch from '../Pictures/Theme/CakeSketch.png';
import RingMobile from '../Pictures/Theme/RingMobile.png';
import DiamondDrawing from '../Pictures/Theme/DiamondDrawing.png';
import BlackHeart from '../Pictures/BlackHeart.png';

function MobileHome() {  
  const [haveToken, setHaveToken] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [timelineInfo, setTimelineInfo] = useState(undefined);
  
  const ourStoryRef = useRef(null);
  const location = useLocation();

  const updateAuthenticate = async () => {
    if( timelineInfo == undefined ) {
      try {
        const ttoken = localStorage.getItem('TinhToken');
        const requestHeader = {
          Authorization: ttoken
        };

        const response = await fetch ('/api/loadPics', {
          method: 'GET',
          headers: requestHeader
        });

        const data = await response.json();

        if(response.status == 200){
          setTimelineInfo(data.timelineinfo);
        }
        else if(response.status == 401)
          setTimelineInfo(undefined);
      }
      catch (err) {
        console.error(err);
      }
    }

  }

  useEffect(() => {
    if(!haveToken)
      setHaveToken(localStorage.getItem('TinhToken') != null);
    else {
      updateAuthenticate();
    }

    if(location.hash) {
      const element = document.querySelector(location.hash);

      if (element)
        element.scrollIntoView({ behavior: 'smooth' });
    }

  }, [loginModalOpen, timelineInfo, haveToken, location])

  return (
    <div className="MobileComponentHome">
      <div className="grid-background">
        <div id="full-content">
          <div className="pages">
            <img className="ruler-top" src={BackroundTopRuler} />
            <div className="tp-images-top-m"> 
              <img className="ring-image-m" src={RingMobile} />
              <div className="top-imgs-stack-m">
                <img className="cake-sketch-m" src={CakeSketch} />
                <div className="sticky-note-m">
                  <h2>In Development</h2>
                  <p>More details to come!</p>
                </div>   
              </div>  
            </div>
            <div className="tp-content-title-bg-m" >
              <div className="tp-content-title-m">
                <p className="tp-details-m">A Tinh Wedding</p>
                <p className="tp-names-m">Samantha & Stephen</p>
                <p className="tp-details-m">10.26.2024 <img className="and-heart" src={BlackHeart} /> Carrollton,TX</p>
              </div>
              <img className="pencil-image-m" src={DraftingPencil} />
            </div>
            <div className="tp-images-bottom-m">
              <div className="img-ring-sketch-m">
                  <img className="diamond-sketch-m" src={DiamondDrawing} />
              </div>
              <PageTableMobile loginModalOpen={setLoginModalOpen} page="home" />
            </div>            
            <img className="ruler-bottom" src={BackroundTopRuler} />            
          </div>
          <div className="pages">  
            <img className="ruler-top" src={BackroundTopRuler} />
            <div className="ap-intro-m">
              <p className="notes-intr-m">NOTES:</p>
              <div className="note-box-m">
                <div className="note-number-m"><p>1</p></div>
                <p>Hi! Welcome to our wonderfully nerdy wedding website! Stephen and I want to extend a heartfelt thank you for taking the time to explore our little corner of the internet. It means the world to us that you're here, sharing in our excitement and journey towards our big day.</p>
              </div>
              <div className="note-box-m">
                <div className="note-number-m"><p>2</p></div>
                <p> As you navigate through the pages filled with our homemade touches and geeky inspirations, we can't help but think about how much we're looking forward to celebrating with you on our wedding day. Your presence will truly make the day even more special and memorable. We can't wait to share in the joy, laughter, and love that will fill the air as we say "I do."</p>
              </div>              
              <div className="note-box-m">
                <div className="note-number-m"><p>3</p></div>
                <p>As Stephen and I learn more about web developement, we'll keep this page posted with what we've added. Hopefully all the information you need to celebrate our day with us will be here. Please let us know if something isn't quite right or if you have any questions. <a href="mailto:contact@FloTinhDownTheAisle.com">contact@FloTinhDownTheAisle.com</a></p>
             </div>
              <div className="note-box-m">
                <div className="note-number-m"><p>4</p></div>
                  <p> 04/09/2024: For the past month, we've been learning all about backend web developement. The latest addition includes a portion of the password protected details we are including in this site. Our first link "Our Story" should be up and running. Please contact Sam or Stephen if you'd like the password to check it out.</p>
              </div>
              <div className="note-box-m">
                <div className="note-number-m"><p>5</p></div>
                  <p> 05/06/2024: It's finished! All portions of the website should be up and running! Please let us know if something isn't quite right or you have any questions.</p>
              </div>
            </div>
            <SecondaryPageTableMobile pageNumber="2" homePath="home" />
            <img ref={ourStoryRef} className="ruler-bottom" src={BackroundTopRuler} />
          </div> 
            <div id="our-story" className="pages">
              { (timelineInfo != undefined) && 
                <>
                <img className="ruler-top" src={BackroundTopRuler} />
                <div ref={ourStoryRef} className="ourStory-m">
                    <h2>Our Story</h2>
                    <p style={{marginBlockStart: "0.5em"}}>Our story starts during the unfortunate arrival of the COVID pandemic. It was 2020 and while most people were required to stay home, our engineering jobs required us to still go into work. It was within those walls where our paths crossed. During one of our talks, Stephen asked Sam to teach him how to play tennis. So we started playing together.</p>
                    <p>This eventually led to our first date, which Stephen even invited Loki to join. Since then, the two of us have bonded over a love for technology, fitness and good food. We have embarked on various outdoor adventures, of course bringing Loki along to join in on the fun. We have experienced amazing things like a hot air balloon ride and a snowboarding trip. We've even had the opportunity to visit Spain, The UK and Ireland together.</p>
                    <p>But while these pictures represent a small piece of what we have enjoyed together, as our wedding day approaches, we cannot wait to share our biggest adventure yet with all of you. We feel so blessed to have so many people to celebrate with and we hope to continue to share our lives with you!</p> 
                </div>
                <VerticalTimeline photos={timelineInfo}/>
                <SecondaryPageTableMobile pageNumber="3" homePath="home" />
                <img className="ruler-bottom" src={BackroundTopRuler} />
                </>
              }
            </div>
        </div>
      </div>
    </div>
  );
}        

export default MobileHome;