import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DesktopHome from './Home';
import DesktopDetails from './Details';
import DesktopLinks from './Links'

function DesktopComponent() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<DesktopHome />} />
        <Route path="/Details" element={<DesktopDetails />} />
        <Route path ="/Links" element={<DesktopLinks />} />
      </Routes>
    </Router>
  );
}

export default DesktopComponent;