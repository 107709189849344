import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MobileHome from './Home';
import MobileDetails from './Details';
import MobileLinks from './Links';

function MobileComponent() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<MobileHome />} />
        <Route path="/Details" element={<MobileDetails />} />
        <Route path="/Links" element={<MobileLinks />} /> 
      </Routes>
    </Router>
  );
}

export default MobileComponent;